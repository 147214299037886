<template>
  <div>
    <v-row align="stretch">
      <v-col cols="12 h4-content mt-0">
        <h4>{{ $t('settings.onlineBooking.general') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.appointments"
          :label="$t('settings.onlineBooking.enableOnlineBooking')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.appointment.payment.active"
          :label="$t('settings.onlineBooking.enablePayments')"
          :disabled="myCompany.booker.payment.provider === 'monetico'"
        />
        <v-alert
          type="error"
          outlined
          dense
          v-if="myCompany.booker.payment.provider === 'monetico'"
        >
          Les paiements pour les réservations ne sont pas disponibles avec
          Monetico.
        </v-alert>
        <v-switch
          inset
          v-model="myCompany.booker.services.list.display.price"
          :label="$t('settings.onlineBooking.displayPrices')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.services.list.display.duration"
          :label="$t('settings.onlineBooking.displayDurations')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.services.enableDownloadCalendar"
          :label="$t('settings.onlineBooking.enableDownloadCalendar')"
          v-if="false"
        />
        <v-switch
          inset
          v-model="myCompany.booker.fastBookingEnabled"
          label="Réservation rapide"
        />
        <v-switch
          inset
          v-model="myCompany.booker.appointment.keepPauseToEnd"
          label="Déplacer les pauses à la fin"
        />
      </v-col>
    </v-row>

    <v-sheet v-if="myCompany.booker.appointments" rounded>
      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.onlineBooking.breakdownOfSchedules') }}</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <v-btn-toggle v-model="myCompany.booker.appointment.cuttingHours">
            <v-btn outlined :value="15"> 15 min </v-btn>
            <v-btn outlined class="mx-1" :value="30"> 30 min </v-btn>
            <v-btn outlined :value="60"> 60 min </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12 h4-content">
          <h4>Découpage affiché</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <v-btn-toggle
            v-model="myCompany.booker.appointment.cuttingHoursDisplay"
          >
            <v-btn outlined :value="5"> 5 min </v-btn>
            <v-btn outlined :value="15"> 15 min </v-btn>
            <v-btn outlined class="mx-1" :value="30"> 30 min </v-btn>
            <v-btn outlined :value="60"> 60 min </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="myCompany.booker.appointment.delayToAppointment"
            type="number"
            label="Délai avant prise de rendez-vous"
            suffix="min"
            outlined
          />
        </v-col>
      </v-row>
      <v-row v-if="myCompany.booker.appointment.payment.active" align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.onlineBooking.payments') }}</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <pourcentage
            v-model="myCompany.booker.appointment.payment.prct"
            :label="$t('settings.onlineBooking.percentageToCharge')"
          />
        </v-col>
      </v-row>

      <v-row v-if="myCompany.booker.appointment.payment.active" align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.onlineBooking.cancellations') }}</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <div class="mt-6">
            <v-alert
              type="error"
              v-if="myCompany.booker.payment.disableWebservice"
              outlined
            >
              Cette option est désactivée car vous le mode webservice est
              désactivé.
            </v-alert>
            <v-switch
              v-model="myCompany.booker.appointment.enableGiftMoneyRefund"
              :label="$t('settings.onlineBooking.enableGiftReimbursement')"
              :disabled="myCompany.booker.payment.disableWebservice"
            />
          </div>
          <div class="mt-6">
            <v-text-field
              v-if="myCompany.booker.appointment.enableGiftMoneyRefund"
              v-model.number="
                myCompany.booker.appointment.delayBeforeGiftRefund
              "
              outlined
              type="number"
              label="
                Délai de remboursement des bons cadeaux (en jours)
              "
            />
          </div>
          <div class="mt-6">
            <v-alert
              type="error"
              v-if="myCompany.booker.payment.disableWebservice"
              outlined
            >
              Cette option est désactivée car vous le mode webservice est
              désactivé.
            </v-alert>
            <v-switch
              v-model="myCompany.booker.appointment.enableMoneyRefund"
              :label="
                $t('settings.onlineBooking.enableReservationReimbursement')
              "
              :disabled="myCompany.booker.payment.disableWebservice"
            />
          </div>
          <div class="mt-6">
            <v-text-field
              v-model.number="myCompany.booker.appointment.delayBeforeCancel"
              outlined
              type="number"
              :label="
                $t('settings.onlineBooking.appointmentCancellationDeadline')
              "
            />
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>{{ $t('settings.onlineBooking.reservation') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.appointment.onlySingle"
          :label="$t('settings.onlineBooking.disableChoiceNumberPeople')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.appointment.allowEmployeeSelection"
          :label="$t('settings.onlineBooking.enableSelectionProvider')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.appointment.noHoleMiddle"
          label="Activer l'anti gruyere pour le milieu de journée"
        />
        <v-switch
          inset
          v-model="myCompany.booker.appointment.noHole"
          label="Activer l'anti gruyere pour le début et fin de journée"
        />
        <v-row
          v-if="
            myCompany.booker.appointment.noHole ||
            myCompany.booker.appointment.noHoleMiddle
          "
        >
          <v-col cols="12">
            <strong>Espacement prioritaire</strong>
          </v-col>
          <v-col cols="12">
            <v-btn-toggle
              v-model="myCompany.booker.appointment.bookingDurationPriority"
            >
              <v-btn outlined :value="0"> Désactivé </v-btn>
              <v-btn outlined :value="15"> 15 min </v-btn>
              <v-btn outlined class="mx-1" :value="30"> 30 min </v-btn>
              <v-btn outlined :value="60"> 60 min </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-switch
          inset
          v-model="myCompany.booker.appointment.useCabinManagement"
          label="Utiliser la gestion des cabines"
        />
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.onlineBooking.notifications') }}
          <span
            v-if="
              myCompany.booker.emailCopyToList &&
              !myCompany.booker.emailCopyList
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              myCompany.booker.emailCopyToList &&
              !myCompany.booker.emailCopyList,
          }"
        />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.emailCopy"
          :label="$t('settings.onlineBooking.receiveCopyEmailsShopAddress')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.emailCopyToList"
          :label="$t('settings.onlineBooking.receiveCopyEmailsAddressList')"
        />
        <v-textarea
          v-show="myCompany.booker.emailCopyToList"
          v-model="myCompany.booker.emailCopyList"
          outlined
          :label="$t('settings.onlineBooking.listOfEmails')"
          :class="{
            empty:
              myCompany.booker.emailCopyToList &&
              !myCompany.booker.emailCopyList,
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Pourcentage from '../helpers/pourcentage'

export default {
  name: 'reservation',
  components: { Pourcentage },
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.initReservation()
  },
  methods: {
    initReservation() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.appointments === 'undefined') {
        Vue.set(this.myCompany.booker, 'appointments', true)
      }

      if (typeof this.myCompany.booker.fastBookingEnabled === 'undefined') {
        Vue.set(this.myCompany.booker, 'fastBookingEnabled', false)
      }

      if (!this.myCompany.booker.appointment) {
        this.myCompany.booker.appointment = {
          payment: { active: false, prct: 100 },
        }
      }

      if (
        typeof this.myCompany.booker.appointment.keepPauseToEnd === 'undefined'
      ) {
        Vue.set(this.myCompany.booker, 'keepPauseToEnd', false)
      }

      if (!this.myCompany.booker.services) {
        this.myCompany.booker.services = {
          list: {
            display: {
              price: true,
              duration: true,
              theme: null,
              mode: null,
            },
            order: null,
          },
        }
      }

      if (!this.myCompany.booker.services.enableDownloadCalendar) {
        Vue.set(this.myCompany.booker.services, 'enableDownloadCalendar', false)
      }

      if (!this.myCompany.booker.appointment.cuttingHours) {
        this.myCompany.booker.appointment.cuttingHours = '15'
      }

      if (!this.myCompany.booker.appointment.cuttingHoursDisplay) {
        Vue.set(this.myCompany.booker.appointment, 'cuttingHoursDisplay', 15)
      }

      if (!this.myCompany.booker.appointment.bookingDurationPriority) {
        Vue.set(this.myCompany.booker.appointment, 'bookingDurationPriority', 0)
      }

      if (!this.myCompany.booker.appointment.delayToAppointment) {
        this.myCompany.booker.appointment.delayToAppointment = 60
      }

      if (typeof this.myCompany.booker.appointment.onlySingle === 'undefined') {
        Vue.set(this.myCompany.booker.appointment.payment, 'onlySingle', false)
      }

      if (
        typeof this.myCompany.booker.appointment.allowEmployeeSelection ===
        'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.appointment.payment,
          'allowEmployeeSelection',
          false
        )
      }

      if (typeof this.myCompany.booker.appointment.noHole === 'undefined') {
        Vue.set(this.myCompany.booker.appointment, 'noHole', false)
      }

      if (
        typeof this.myCompany.booker.appointment.noHoleMiddle === 'undefined'
      ) {
        Vue.set(this.myCompany.booker.appointment, 'noHoleMiddle', false)
      }

      if (
        typeof this.myCompany.booker.appointment.bookingDurationPriority ===
        'undefined'
      ) {
        Vue.set(this.myCompany.booker.appointment, 'bookingDurationPriority', 0)
      }

      if (
        typeof this.myCompany.booker.appointment.useCabinManagement ===
        'undefined'
      ) {
        Vue.set(this.myCompany.booker.appointment, 'useCabinManagement', false)
      }

      if (
        typeof this.myCompany.booker.appointment.enableGiftMoneyRefund ===
        'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.appointment.payment,
          'enableGiftMoneyRefund',
          false
        )
      }

      if (
        typeof this.myCompany.booker.appointment.delayBeforeGiftRefund ===
        'undefined'
      ) {
        Vue.set(this.myCompany.booker.appointment, 'delayBeforeGiftRefund', 14)
      }

      if (
        typeof this.myCompany.booker.appointment.enableMoneyRefund ===
        'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.appointment.payment,
          'enableMoneyRefund',
          false
        )
      }

      if (
        typeof this.myCompany.booker.appointment.delayBeforeCancel === undefined
      ) {
        Vue.set(this.myCompany.booker.appointment, 'delayBeforeCancel', 24)
      }

      if (typeof this.myCompany.booker.emailCopy === 'undefined') {
        Vue.set(this.myCompany.booker, 'emailCopy', true)
      }

      if (typeof this.myCompany.booker.emailCopyToList === 'undefined') {
        Vue.set(this.myCompany.booker, 'emailCopyToList', false)
      }

      if (!this.myCompany.booker.emailCopyList) {
        Vue.set(this.myCompany.booker, 'emailCopyList', '')
      }

      // TODO: A SUPPRIMER
      this.myCompany.booker.services.enableDownloadCalendar = false
    },
  },
}
</script>

<style scoped></style>
