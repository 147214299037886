<template>
  <div>
    <v-tabs v-model="tab" class="ma-0 py-5 px-2" slider-color="primary">
      <v-tab>
        <span>{{ $t('settings.emails.general') }}</span>
      </v-tab>
      <v-tab
        ><span>{{ $t('edit.gallery') }}</span></v-tab
      >
      <v-tab>
        <span>{{ $t('edit.openingTime') }}</span>
      </v-tab>
      <v-tab><span>Booker</span></v-tab>
      <v-tab v-if="false"><span>PassBeauté</span></v-tab>
      <v-tab v-if="false">
        <span>{{ $t('database.resources') }}</span>
      </v-tab>
      <v-tab v-if="false">
        <span>{{ $t('shops.geoGroups') }}</span>
      </v-tab>
      <v-tab>
        <span>{{ $t('shops.sms') }}</span>
      </v-tab>
      <v-tab>
        <span>{{ $t('edit.geolocation') }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="mx-5 pa-5">
        <!-- <div class="content-carousel">
          <VueSlickCarousel
            v-if="updateSlick"
            ref="carousel"
            :slidesToShow="3"
            :infinite="false"
            :swipeToSlide="true"
          >
            <template #prevArrow>
              <v-btn dark icon class="custom-arrow-left">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <template #nextArrow>
              <v-btn dark icon class="custom-arrow-right">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <div
              v-for="(image, i) in slideImage"
              :key="'image-carousel-shop' + i"
            >
              <div class="slide-choose-carousel">
                <v-btn-toggle dark dense class="btn-toggle-carousel">
                  <ImageChooser v-model="imagesUploads[i]" />
                  <v-btn
                    v-if="image !== placeHolderImage"
                    icon
                    @click="selectImage(i)"
                  >
                    <v-icon color="white">mdi-information-variant</v-icon>
                  </v-btn>
                  <v-btn v-if="i !== 0" icon @click="deleteImage(i)">
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </v-btn-toggle>
                <v-img :src="image" class="image-choose-carousel" />
              </div>
            </div>
          </VueSlickCarousel>
          <div v-else class="carousel-loader" />
        </div>
        <v-btn
          class="btn-add-img"
          :dark="imagesUploads[imagesUploads.length - 1] !== null"
          @click="addImage"
          :disabled="imagesUploads[imagesUploads.length - 1] === null"
        >
          <span class="txt-add-img">Ajouter une image</span>
          <v-icon color="white">mdi-camera</v-icon>
        </v-btn> -->
        <div class="line mt-5">
          <div class="col-40">
            <v-text-field
              outlined
              v-model="myShop.slug"
              :placeholder="$t('edit.webAddress')"
              type="text"
              autocomplete="slug"
              label="Slug"
              :class="{ empty: !myShop.slug }"
            />
          </div>
          <div class="col-60 pl-3">
            <v-text-field
              outlined
              v-model="myShop.name"
              :placeholder="$t('edit.shopName')"
              type="text"
              :label="$t('edit.shopName')"
              autocomplete="off"
              :class="{ empty: !myShop.name }"
            />
          </div>
        </div>
        <div class="line">
          <div class="col-50 pr-3">
            <v-text-field
              outlined
              v-model="myShop.email"
              type="mail"
              :rules="[rules.email]"
              :placeholder="$t('edit.shopEmail')"
              :label="$t('edit.shopEmail')"
              :class="{ empty: !myShop.email }"
            />
          </div>
          <div class="col-50">
            <!-- <MazPhoneNumberInput
              v-model="myShop.phone"
              @update="myShop.phoneDetails = $events"
            /> -->
            <PhoneNumberInput
              v-model="myShop.phone"
              @input="(val, obj) => (myShop.phoneDetails = obj)"
              :inputOptions="{ placeholder: $t('edit.phoneNumber') }"
              style="height: 56px"
              :style="{ borderColor: !myShop.phone ? 'red' : undefined }"
            />
            <!-- <vue-tel-input
              v-model="myShop.phone"
              @input="(val, obj) => (phoneDtls = obj)"
              :inputOptions="{ placeholder: 'Numéro de téléphone' }"
              style="height: 56px"
            /> -->
            <!-- <vue-phone-number-input
              v-model="myShop.phone"
              :translations="{
                countrySelectorLabel: 'Code Pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone',
                example: 'Exemple :'
              }"
              default-country-code="FR"
              size="lg"
              @update="updatePhone($events)"
            ></vue-phone-number-input> -->
          </div>
        </div>
        <div class="line">
          <div class="col-100">
            <v-text-field
              outlined
              v-model="myShop.publicInfo.address.address"
              type="mail"
              :label="$t('edit.shopAddress')"
              autocomplete="off"
              :class="{ empty: !myShop.publicInfo.address.address }"
            />

            <!-- <v-autocomplete
              v-model="myShop.publicInfo.address.address"
              :items="addressItems"
              :loading="addressLoading"
              :search-input.sync="addressSearch"
              label="Adresse de la boutique"
              placeholder="Commencez à chercher"
              @input="onGeologSelect"
              no-filter
              outlined
            ></v-autocomplete> -->
          </div>
        </div>
        <div class="line">
          <div class="col-20">
            <v-text-field
              outlined
              v-model="myShop.publicInfo.address.zipcode"
              type="text"
              :label="$t('settings.identity.postalCode')"
              autocomplete="off"
              :class="{ empty: !myShop.publicInfo.address.zipcode }"
            />
          </div>
          <div class="col-50 pl-3">
            <v-text-field
              outlined
              v-model="myShop.publicInfo.address.city"
              type="text"
              :label="$t('settings.identity.city')"
              autocomplete="off"
              :class="{ empty: !myShop.publicInfo.address.city }"
            />
          </div>
          <div class="col-30 pl-3">
            <v-text-field
              outlined
              v-model="myShop.publicInfo.address.country"
              type="text"
              :label="$t('edit.country')"
              :class="{ empty: !myShop.publicInfo.address.country }"
            />
          </div>
        </div>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <div>
          <images-selector
            v-model="myShop.images"
            :size="size"
            :updateData="updateData"
            :categories="images"
            :imageSizes="sizes"
            @delete="removeImage"
          />
          <file-explorer
            :images="images"
            :images-already-selected="getShopImages"
            :loading="loadingBanque"
            class="mt-5"
            @uploadImages="uploadImages"
          />
        </div>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <v-container class="py-6">
          <v-dialog v-model="openHoursDialog" width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="updateHours"
                tile
                text
                class="mb-6"
              >
                <v-icon left>mdi-pencil</v-icon>
                {{ $t('edit.changeOpeningHours') }}
              </v-btn>
            </template>
            <action-modal
              @actionModal="dialogHoursValidation"
              :title="$t('edit.openingHoursShop')"
              id="openhours"
            >
              {{ openingHoursUpdate }} - {{ myShop.id }}
              <template v-slot:actionModalContent>
                <v-sheet
                  style="z-index: 999999"
                  v-for="(day, dayIndex) in orderDays"
                  :key="'u' + dayIndex"
                >
                  <v-row dense>
                    <v-col dense class="pb-0">
                      <span class="title">{{
                        getFrenchDayName(openingHoursUpdate[day].day)
                      }}</span>
                    </v-col>
                    <v-col dense cols="9" class="pb-0" align="end">
                      <v-btn-toggle
                        dense
                        tile
                        group
                        v-model="openingHoursUpdate[day].status"
                        mandatory
                      >
                        <v-btn value="day" small>
                          {{ $t('edit.wholeDay') }}
                        </v-btn>
                        <v-btn value="pause" small>
                          {{ $t('edit.lunchBreak') }}
                        </v-btn>
                        <v-btn value="closed" small>
                          {{ $t('edit.closed') }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col dense cols="12" sm="3" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        solo
                        flat
                        v-model="openingHoursUpdate[day].hours[0].start"
                        type="time"
                        :label="$t('edit.start')"
                        :disabled="openingHoursUpdate[day].status === 'closed'"
                      />
                    </v-col>
                    <v-col dense cols="12" sm="3" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        solo
                        flat
                        v-model="openingHoursUpdate[day].hours[0].end"
                        type="time"
                        :label="$t('edit.end')"
                        :disabled="openingHoursUpdate[day].status !== 'pause'"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        solo
                        flat
                        v-model="openingHoursUpdate[day].hours[1].start"
                        type="time"
                        :label="$t('edit.start')"
                        :disabled="openingHoursUpdate[day].status !== 'pause'"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="py-0">
                      <v-text-field
                        outlined
                        dense
                        solo
                        flat
                        v-model="openingHoursUpdate[day].hours[1].end"
                        type="time"
                        :label="$t('edit.end')"
                        :disabled="openingHoursUpdate[day].status === 'closed'"
                      />
                    </v-col>
                  </v-row>
                </v-sheet>
              </template>
            </action-modal>
          </v-dialog>
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t('edit.day') }}</th>
                    <th class="text-end">{{ $t('edit.hours') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="font-size: 1.2em"
                    v-for="openHour in orderDays"
                    :key="openingHours[openHour].day"
                  >
                    <td>{{ daysToHuman(openingHours[openHour].day) }}</td>
                    <td class="text-end" :class="isRed">
                      <span v-if="openingHours[openHour].status === 'closed'">
                        {{ $t('edit.closed') }}
                      </span>
                      <span v-else-if="openingHours[openHour].status === 'day'">
                        {{
                          formatedHour(openingHours[openHour].hours[0].start)
                        }}
                        -
                        {{ formatedHour(openingHours[openHour].hours[1].end) }}
                      </span>
                      <span v-else>
                        {{
                          formatedHour(openingHours[openHour].hours[0].start)
                        }}
                        -
                        {{ formatedHour(openingHours[openHour].hours[0].end) }}
                        /
                        {{
                          formatedHour(openingHours[openHour].hours[1].start)
                        }}
                        -
                        {{ formatedHour(openingHours[openHour].hours[1].end) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <v-container>
          <v-switch
            :label="$t('edit.activateAppointmentBooking')"
            v-model="myShop.booker.active"
          ></v-switch>
          <v-card v-if="myShop.booker.active">
            <v-tabs>
              <v-tab>{{ $t('edit.settings') }}</v-tab>
              <v-tab>{{ $t('edit.shortDescription') }}</v-tab>
              <v-tab>{{ $t('edit.longDescription') }}</v-tab>
              <v-tab-item>
                <div class="py-8 px-4">
                  <!-- <v-switch
                    label="Activer la vente de bon cadeau hors des boutiques"
                    v-model="myShop.booker.gift"
                  /> -->
                  <pourcentage
                    v-if="
                      jsonHelpers.getJSONValue(
                        $store.state.auth.company,
                        'booker.appointment.payment.active',
                        false
                      )
                    "
                    v-model="myShop.booker.appointment.payment.prct"
                    :label="$t('settings.onlineBooking.percentageToCharge')"
                    hide-details
                  />
                  <v-switch
                    :label="$t('edit.sendEmailToShopWhenReservations')"
                    v-model="myShop.booker.appointment.receiveEmailCopy"
                  />
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="py-8 px-4" v-if="myShop.booker.description">
                  <multilang-textarea
                    style="width: 100%"
                    orientation="vertical"
                    v-model="myShop.booker.description.short"
                    :label="$t('edit.shortDescription')"
                  />
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="py-8 px-4" v-if="myShop.booker.description">
                  <multilang-textarea
                    style="width: 100%"
                    orientation="vertical"
                    v-model="myShop.booker.description.long"
                    :label="$t('edit.longDescription')"
                  />
                </div>
              </v-tab-item>
            </v-tabs>
          </v-card>
          <div class="mt-6">
            <div>Message d'alerte :</div>
            <RichEditor
              v-model="myShop.booker.customMessage"
              height="200px"
              label="Message d'alerte"
            />
          </div>
        </v-container>
      </v-tab-item>
      <!-- PassBeauté -->
      <v-tab-item v-if="false" class="mx-5 pa-5">
        <v-container>
          <v-switch
            :label="$t('edit.activatePass', { pass: 'Pass Beauté' })"
            v-model="myShop.passport.active"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item v-if="false" class="mx-5 pa-5">
        {{ $t('database.resources') }}
      </v-tab-item>
      <v-tab-item v-if="false" class="mx-5 pa-5">
        {{ $t('icons.geoGroups') }}
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <v-switch
          :label="$t('settings.sms.bookingEnabled')"
          v-model="myShop.booker.sms.bookingEnabled"
        />
        <v-text-field
          :label="$t('settings.sms.username')"
          v-model="myShop.booker.sms.username"
          outlined
        />
        <v-text-field
          :label="$t('settings.sms.password')"
          v-model="myShop.booker.sms.password"
          outlined
        />
        <v-text-field
          :label="$t('settings.sms.bookingDelay')"
          v-model.number="myShop.booker.sms.bookingDelay"
          outlined
          type="number"
          suffix="h"
          style="width: 200px"
        />
        <v-switch
          inset
          v-model="myShop.booker.sms.customBookingRemind"
          label="Surcharger le texte des rappels de réservation"
        />
        <SmsTextarea
          v-model="myShop.booker.sms.templates.bookingRemind"
          :label="$t('settings.sms.bookingRemind')"
          :vars="['date', 'shop', 'firstname', 'lastname', 'address']"
          v-if="myShop.booker.sms.customBookingRemind"
        />
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <strong>{{ $t('edit.fullAddress') }} :</strong><br />
              <span>{{ completeAdress }}</span>
            </v-col>
            <v-col cols="12">
              <strong>{{ $t('edit.mapSettings') }} :</strong><br />

              <!-- <v-switch
                v-model="myShop.localisation.tooltip"
                label="Afficher le tooltip"
              /> -->

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="myShop.localisation.coordonate.lat"
                    type="text"
                    :label="$t('edit.latitude')"
                    hide-details
                    :class="{
                      empty: myShop.localisation.coordonate.lat === 48.856614,
                    }"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="myShop.localisation.coordonate.lng"
                    type="text"
                    :label="$t('edit.longitude')"
                    hide-details
                    :class="{
                      empty: myShop.localisation.coordonate.lng === 2.3522219,
                    }"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    @click="actionGeolocalisation"
                    block
                    tile
                    :disabled="!addressIsComplete"
                  >
                    {{ $t('edit.autoFill') }}
                  </v-btn>
                  <div v-if="!addressIsComplete" class="text-center grey--text">
                    {{ $t('edit.addressMustBeEntered') }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-alert
                v-if="!myShop.publicInfo || !addressIsComplete"
                type="warning"
              >
                {{ $t('edit.enterAddressToDisplayMap') }}
              </v-alert>
              <!-- <iframe
                :src="myShop.publicInfo.mapURL"
                style="border: none; width: 100%; height: 350px;"
                v-if="myShop.publicInfo && myShop.publicInfo.mapURL"
              /> -->
              <map-free
                :id="'p' + myShop.id"
                :tooltipPermanent="true"
                :tooltip="myShop.localisation.tooltip"
                :coordonate="myShop.localisation.coordonate"
              >
                <template v-slot:toolTipInfo>
                  <strong>{{ myShop.name }}</strong>
                  <div>{{ myShop.publicInfo.address.address }}</div>
                  <div>
                    {{ myShop.publicInfo.address.zipcode }}
                    {{ myShop.publicInfo.address.city }}
                  </div>
                </template>
              </map-free>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="loading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import uploadsHelpers from '@/helpers/uploads'
import geolocalisation from '@/helpers/geoLocalisation'
import MapFree from './mapFree.vue'
import MultilangTextarea from '../../helpers/multilangTextarea.vue'
import ActionModal from '../../helpers/actionModal.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import swal from 'sweetalert'
import ImagesSelector from '@/components/helpers/imagesSelector.vue'
import slugify from 'slugify'
import jsonHelpers from '@/helpers/json'
import Pourcentage from '@/components/helpers/pourcentage'

import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

import PhoneNumberInput from '@/components/general/phone/components/vue-tel-input'

import geocodingApi from '@/helpers/geocoding-api'
import banqueImages from '../../../helpers/banqueImages'
import FileExplorer from '../../images/fileExplorer'
import SmsTextarea from '@/components/helpers/smsTextarea'
import imageSizes from '@/helpers/imageSizes'
import RichEditor from '@/components/helpers/richEditor'

export default {
  components: {
    RichEditor,
    SmsTextarea,
    Pourcentage,
    //   BookerInfo,
    // ImageChooser,
    // VuePhoneNumberInput,
    // Passport,
    MapFree,
    MultilangTextarea,
    // VueSlickCarousel,
    ActionModal,
    ImagesSelector,
    PhoneNumberInput,
    FileExplorer,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      },
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      currentOverrideArticle: null,
      addressItems: [],
      addressLoading: false,
      addressSearch: '',
      dontSearch: true,
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'email invalide'
        },
      },
      loading: false,
      updateSlick: true,
      tab: null,
      expansionValue: null,
      actionsShop: 0,
      myShop: null,
      imageSelected: null,
      idImageSelected: null,
      imagesUploads: [null],
      imagesBeforeDelete: [],
      openingHours: {
        monday: {
          day: 'monday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        tuesday: {
          day: 'tuesday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        wednesday: {
          day: 'wednesday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        thursday: {
          day: 'thursday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        friday: {
          day: 'friday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        saturday: {
          day: 'saturday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
        sunday: {
          day: 'sunday',
          hours: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '19:00' },
          ],
          status: 'closed',
        },
      },
      openHoursDialog: false,
      openingHoursUpdate: null,
      phoneDtls: null,
      placeHolderImage:
        'https://via.placeholder.com/600x400/000000/FFFFFFF?text=Veuillez+définir+une+image',
      jsonHelpers,
      images: [],
      updateData: [],
      getShopImages: [],
      loadingBanque: false,
      orderDays: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
    }
  },
  created() {
    this.myShop = JSON.parse(JSON.stringify(this.item))

    // this.myShop.phone = this.myShop.phoneDetails
    //   ? this.myShop.phoneDetails.formatInternational
    //   : null

    this.addressItems = [
      {
        text: this.myShop.publicInfo.address.address,
        value: this.myShop.publicInfo.address.address,
      },
    ]

    if (!this.myShop.booker.sms) {
      Vue.set(this.myShop.booker, 'sms', {})
    }

    if (!this.myShop.booker.sms.bookingEnabled) {
      Vue.set(this.myShop.booker.sms, 'bookingEnabled', false)
    }

    if (!this.myShop.booker.sms.username) {
      Vue.set(this.myShop.booker.sms, 'username', '')
    }

    if (!this.myShop.booker.sms.password) {
      Vue.set(this.myShop.booker.sms, 'password', '')
    }

    if (!this.myShop.booker.sms.bookingDelay) {
      Vue.set(this.myShop.booker.sms, 'bookingDelay', 4)
    }

    if (!this.myShop.booker.sms.customBookingRemind) {
      Vue.set(this.myShop.booker.sms, 'customBookingRemind', false)
    }

    if (!this.myShop.booker.sms.templates) {
      Vue.set(this.myShop.booker.sms, 'templates', {})
    }

    if (!this.myShop.booker.sms.templates.bookingRemind) {
      Vue.set(this.myShop.booker.sms.templates, 'bookingRemind', '')
    }

    if (!this.myShop.booker.description) {
      this.myShop.booker.description = { short: null, long: null }
    }

    if (!this.myShop.booker.description) {
      this.myShop.booker.description = { short: null, long: null }
    }

    if (this.myShop.openingHours) {
      this.openingHours = { ...this.myShop.openingHours }
    }
    if (!this.myShop.localisation.coordonate) {
      this.myShop.localisation = {
        coordinates: { lat: 48.856614, lng: 2.3522219 },
      }
    } else if (this.myShop.localisation.coordonate.lat === null) {
      this.myShop.localisation = {
        coordonate: { lat: 48.856614, lng: 2.3522219, tooltip: false },
      }
    }
    if (this.myShop.booker.gift === undefined) {
      this.myShop.booker.gift = false
    }
    if (this.myShop.booker.priceOverrides === undefined) {
      Vue.set(this.myShop.booker, 'priceOverrides', [])
    }

    // Appointment
    if (!this.myShop.booker.appointment) {
      Vue.set(this.myShop.booker, 'appointment', {})
    }
    if (!this.myShop.booker.appointment.payment) {
      Vue.set(this.myShop.booker.appointment, 'payment', {})
    }
    if (!this.myShop.booker.appointment.payment.prct) {
      Vue.set(this.myShop.booker.appointment.payment, 'prct', 100)
    }
    if (!this.myShop.booker.appointment.receiveEmailCopy) {
      Vue.set(this.myShop.booker.appointment, 'receiveEmailCopy', false)
    }

    this.myShop.images = this.myShop.imageUrl
    // this.imagesUploads = this.myShop.imageUrl || [null]
  },
  computed: {
    shopArticles() {
      const articles = this.$store.state.articles.articles

      const as = this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: [],
      }).find((s) => s.abId === this.item.abId).articles

      return articles.filter((a) => as.map((aa) => aa.id).includes(a.id))
    },
    shopArticlesNotAdded() {
      return this.shopArticles.filter(
        (a) =>
          !this.myShop.booker.priceOverrides.map((b) => b.id).includes(a.id)
      )
    },
    addressItemsCmp() {
      return this.addressItems
    },
    addressIsComplete() {
      if (!this.myShop.publicInfo.address.address) {
        return false
      }
      if (!this.myShop.publicInfo.address.zipcode) {
        return false
      }
      if (!this.myShop.publicInfo.address.city) {
        return false
      }
      return true
    },
    completeAdress() {
      const addressArr = []

      addressArr.push(this.myShop.publicInfo.address.address || null)
      addressArr.push(this.myShop.publicInfo.address.zipcode || null)
      addressArr.push(this.myShop.publicInfo.address.city || null)
      addressArr.push(this.myShop.publicInfo.address.country || null)

      return addressArr.filter((i) => !!i).join(', ')
    },
    slideImage: function () {
      const cur = this.imagesUploads.reduce((prev, cur) => {
        if (cur) {
          if (cur.url) {
            prev.push(cur.url)
          } else if (cur.value) {
            prev.push(cur.value.url)
          }
        } else {
          prev.push(this.placeHolderImage)
        }

        return prev
      }, [])

      if (cur.length === 0) {
        cur.push(
          'https://via.placeholder.com/600x400/000000/FFFFFFF?text=Veuillez+définir+une+image'
        )
      }
      return cur
    },
    nickname() {
      return this.$store.state.auth.company.nickname
    },
    isRed() {
      if (this.detectAllClosed()) {
        return 'red--text'
      } else {
        return ''
      }
    },
    size() {
      return this.$store.state.auth.company &&
        this.$store.state.auth.company.themes &&
        this.$store.state.auth.company.themes.monoShop &&
        this.$store.state.auth.company.themes.monoShop.id
        ? imageSizes.shop[this.$store.state.auth.company.themes.monoShop.id]
        : imageSizes.shop.default
    },
    sizes() {
      return imageSizes.shop
    },
  },
  watch: {
    addressSearch(val) {
      if (this.dontSearch) {
        this.dontSearch = false
        return
      }
      this.addressLoading = true
      window.clearTimeout(window.addressTimeout)
      window.addressTimeout = window.setTimeout(async () => {
        this.addressLoading = false
        const items = await geocodingApi.autocomplete(val)
        this.addressItems = items.map((item) => ({
          value: item,
          text: item.name,
        }))
      }, 2000)
    },
    submit: async function (val) {
      if (val) {
        this.loading = true
        const response = await this.submitForm()

        if (response.error) {
          this.loading = false
          this.$emit('isSubmit', false)
          return swal({
            text: response.message,
            icon: 'error',
          })
        }

        this.loading = false

        this.$emit('isSubmit', true)

        // if (this.imagesUploads[this.imagesUploads.length - 1] === null) {
        //   swal({
        //     buttons: {
        //       cancel: 'Compris !'
        //     },
        //     title: 'Photo non défini',
        //     text: 'Vous ne pouvez pas laisser une image indéfini',

        //     dangerMode: true
        //   })
        //   this.$emit('isSubmit', false)
        // } else {
        // this.submitForm()
        // this.$emit('isSubmit', true)
        // }
      }
    },
    item: {
      handler: function () {
        // this.myShop = val
        // this.openingHours = this.myShop.openingHours
        //   ? this.myShop.openingHours
        //   : this.openingHours
        // this.imagesUploads = this.myShop.imageUrl || [null]
        // this.actionsShop = 0
        // this.idImageSelected = null
        // this.imageSelected = null
        // this.expansionValue = -1
        // if (!this.myShop.booker.description) {
        //   this.myShop.booker.description = { short: null, long: null }
        // }
        // if (!this.myShop.localisation.coordonate) {
        //   this.myShop.localisation = {
        //     coordinates: { lat: 48.856614, lng: 2.3522219 }
        //   }
        // } else if (this.myShop.localisation.coordonate.lat === null) {
        //   this.myShop.localisation = {
        //     coordonate: { lat: 48.856614, lng: 2.3522219, tooltip: false }
        //   }
        // }
        // if (this.myShop.booker.gift === undefined) {
        //   this.myShop.booker.gift = false
        // }
      },
    },
    openingHours: {
      handler() {
        this.detectAllClosed()
      },
      deep: true,
    },
  },
  methods: {
    addPriceOverride() {
      if (!this.currentOverrideArticle) {
        return
      }

      this.myShop.booker.priceOverrides.push({
        id: this.currentOverrideArticle.id,
        name: this.currentOverrideArticle.name.fr,
        newPrice: this.currentOverrideArticle.prices,
      })

      Vue.set(
        this.myShop.booker,
        'priceOverrides',
        this.myShop.booker.priceOverrides
      )

      this.$forceUpdate()

      this.currentOverrideArticle = null
    },
    onGeologSelect(val) {
      this.dontSearch = true

      this.myShop.publicInfo.address.address = val.name
      this.myShop.publicInfo.address.zipcode = val.postal_code
      this.myShop.publicInfo.address.city = val.locality
      this.myShop.publicInfo.address.country = val.country
      this.myShop.publicInfo.mapURL = val.map_url
      // this.myShop.localisation = {
      //   coordinates: { lat: val.latitude, lng: val.longitude }
      // }
      this.myShop.localisation.coordonate = {
        lat: val.latitude,
        lng: val.longitude,
      }

      this.addressItems = [
        {
          text: this.myShop.publicInfo.address.address,
          value: this.myShop.publicInfo.address.address,
        },
      ]
    },
    async actionGeolocalisation() {
      const coordonate = await this.getCoordinates(this.completeAdress)
      if (coordonate.length) {
        this.myShop.localisation.coordonate = {
          lat: coordonate[0].geometry.coordinates[1],
          lng: coordonate[0].geometry.coordinates[0],
        }
      }
    },
    actionBooker(val) {
      this.actionsShop = 0
      if (val) {
        this.myShop.booker = val
      }
    },
    actionPassport(val) {
      this.actionsShop = 0
      if (val) {
        this.myShop.passport = val
      }
    },
    validImage() {
      this.imagesUploads[this.idImageSelected] = this.imageSelected
      this.actionsShop = 0
    },
    selectImage(i) {
      this.idImageSelected = i
      this.imageSelected = { ...this.imagesUploads[i] }
      this.actionsShop = 3
    },
    deleteImage(i) {
      let c = 0
      let newImages = []
      let delImages = []
      const tabsimg = [...this.imagesUploads]
      for (const elt of tabsimg) {
        if (c === i) {
          delImages.push(elt)
        } else {
          newImages.push(elt)
        }
        c++
      }
      this.imagesUploads = [...newImages]
      this.imagesBeforeDelete = [...this.imagesBeforeDelete, ...delImages]
    },
    addImage() {
      this.imagesUploads.push(null)
      this.updateSlick = false
      setTimeout(() => {
        this.updateSlick = true
      }, 50)
      setTimeout(() => {
        this.$refs.carousel.goTo(this.imagesUploads.length)
      }, 100)
    },
    async delImage(imageName) {
      return await uploadsHelpers.deleteImage(
        'admin/artebeaute/shops',
        imageName
      )
    },
    async submitForm() {
      let imagesUrl = []
      // if (this.imagesBeforeDelete) {
      //   for (const imgDel of this.imagesBeforeDelete) {
      //     const tabs = imgDel.url.split('/')
      //     const imgName = tabs[tabs.length - 1]
      //     await this.delImage(imgName)
      //   }
      // }
      // console.log(imagesUrl)
      try {
        // console.log('imageupload', this.imagesUploads)
        for (let i = 0; i < this.myShop.images.length; i++) {
          if (this.myShop.images[i].new) {
            const image = this.myShop.images[i].blob
            // const imageLazy = this.myShop.images[i].blobLazy
            const fileName = uploadsHelpers.randomName()

            /*
            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'admin/artebeaute/shops/' + this.myShop.id,
              fileName
            )
            const lazyImageUrl = await uploadsHelpers.uploadSingleBase64(
              imageLazy,
              'admin/artebeaute/shops/' + this.myShop.id,
              fileName + '-lazy'
            )
            imagesUrl.push({
              url: imageUrl,
              lazyUrl: lazyImageUrl,
              description: this.myShop.images[i].description,
              alt: this.myShop.images[i].alt
            })*/

            const directory = this.myShop.images[i].category
              ? 'imagekit/eden/bank/shops/' + this.myShop.images[i].category
              : 'imagekit/eden/shops'

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              directory,
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.myShop.images[i].description,
              alt: this.myShop.images[i].alt,
            })
          } else {
            imagesUrl.push(this.myShop.images[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccurredImages'),
        }
      }

      if (this.myShop.id) {
        const shopUpdated = {
          data: {
            slug: slugify(this.myShop.slug, {
              strict: true,
            }).toLowerCase(),
            images: imagesUrl,
            artebeauteId: this.myShop.abId,
            openingHours: this.openingHours,
            publicInfo: {
              name: this.myShop.name,
              email: this.myShop.email,
              phone: this.myShop.phoneDetails.number,
              siret: this.myShop.siret,
              society: this.myShop.society,
              address: {
                address: this.myShop.publicInfo.address.address,
                city: this.myShop.publicInfo.address.city,
                country: this.myShop.publicInfo.address.country,
                zipcode: this.myShop.publicInfo.address.zipcode,
              },
              mapURL: this.myShop.publicInfo.mapURL,
            },
            daysOff: {},
            localisation: this.myShop.localisation,
            booker: this.myShop.booker,
            passport: this.myShop.passport,
            active: true,
          },
          id: this.myShop.id,
        }

        const rep = await this.$store.dispatch('shop/updateShop', shopUpdated)

        if (rep.error) {
          if (rep.code === 409) {
            return { error: true, message: this.$t('edit.slugMustBeUnique') }
          } else {
            return {
              error: true,
              message: this.$t('edit.anErrorOccurredWhileSavingShop'),
            }
          }
        }

        this.imagesUploads = [...imagesUrl]
        if (rep) this.$emit('input', false)

        return {
          success: true,
        }
      }
    },
    daysToHuman(day) {
      const table = {
        monday: this.$t('days.monday'),
        tuesday: this.$t('days.tuesday'),
        wednesday: this.$t('days.wednesday'),
        thursday: this.$t('days.thursday'),
        friday: this.$t('days.friday'),
        saturday: this.$t('days.saturday'),
        sunday: this.$t('days.sunday'),
      }

      return table[day]
    },
    formatedHour(hour) {
      return hour.split(':').join('h')
    },
    getFrenchDayName(day) {
      const daysNames = {
        monday: this.$t('days.monday'),
        tuesday: this.$t('days.tuesday'),
        wednesday: this.$t('days.wednesday'),
        thursday: this.$t('days.thursday'),
        friday: this.$t('days.friday'),
        saturday: this.$t('days.saturday'),
        sunday: this.$t('days.sunday'),
      }

      return daysNames[day]
    },
    dialogHoursValidation(e) {
      if (e) {
        this.validHours()
      }
      this.openHoursDialog = false
    },
    updateHours() {
      this.openingHoursUpdate = JSON.parse(JSON.stringify(this.openingHours))
    },
    validHours() {
      this.openingHours = JSON.parse(JSON.stringify(this.openingHoursUpdate))
    },
    // updatePhone(val) {
    //   this.phone = val.formatInternational
    //   this.country = getCountry().search(val.countryCode)
    //   console.log(val)
    // },
    // updatePhoneNumber(val) {
    //   this.phone = val.nationalNumber
    //   this.country = val.countryCode
    //   console.log(val)
    // },
    async getCoordinates() {
      const rep = await geolocalisation.findCoordinatesByAdress(
        'fr',
        this.myShop.publicInfo.address.address +
          ' ' +
          this.myShop.publicInfo.address.zipcode +
          ' ' +
          this.myShop.publicInfo.address.city
      )
      return rep
    },
    uploadImages(images) {
      images.forEach((img) => {
        if (!this.myShop.images.find((i) => i.url === img.url)) {
          this.myShop.images.push(img)
        }
      })
      this.updateData = this.myShop.images
      this.getShopImages = this.myShop.images
    },
    removeImage(id) {
      this.getShopImages.splice(id, 1)
    },
    detectAllClosed() {
      let allClosed = true
      Object.keys(this.openingHours).forEach((key) => {
        if (this.openingHours[key].status !== 'closed') {
          allClosed = false
        }
      })
      return allClosed
    },
  },
  async mounted() {
    let ourImages,
      yourImages = []
    this.loadingBanque = true
    await banqueImages.getBoutiques().then((res) => {
      ourImages = res
    })
    await banqueImages.getThem(this.nickname, 'shops').then((res) => {
      yourImages = res
    })
    yourImages.forEach((y) => {
      if (ourImages.find((o) => o.title === y.title)) {
        const src = ourImages.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImages.push(y)
      }
    })
    ourImages.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })
    this.images = ourImages
    this.loadingBanque = false

    this.getShopImages = this.myShop.images
  },
}
</script>

<style>
@import '../../../helpers/edit-panel.css';

.openingHoursSheet {
  overflow-y: auto;
  height: 60vh;
}
.grid-info {
  width: 100%;
  display: grid;
  grid-template-columns: 30px auto;
}
.grid-info > div {
  margin-bottom: auto;
  margin-top: auto;
}
.line {
  display: flex;
}

.col-20 {
  width: 20%;
}
.col-30 {
  width: 30%;
}
.col-40 {
  width: 40%;
}
.col-50 {
  width: 50%;
}
.col-60 {
  width: 60%;
}
.col-100 {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  [class*='col-'] {
    width: 100%;
  }
}

.maz-phone-number-input .country-selector .maz-input {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  height: 56px !important;
}

.maz-phone-number-input .maz-input.input-phone-number {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  height: 56px !important;
}

.maz-phone-number-input__country-flag {
  bottom: 0.8rem !important;
}
</style>
