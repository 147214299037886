<template>
  <div>
    <!--    <div class="h4-content">-->
    <!--      <h4>{{ $t('settings.emails.emailsTheme') }}</h4>-->
    <!--      <span />-->
    <!--    </div>-->
    <!--    <MailEditor-->
    <!--      :editing="showMailPopup"-->
    <!--      :default-settings="myCompany.identity.mail.settings"-->
    <!--      @onClose="showMailPopup = false"-->
    <!--      @onSave="saveMailTemplate"-->
    <!--    />-->
    <!--    <iframe :srcdoc="getFullMailTemplateHTML" class="iframe-mail" />-->
    <!--    <v-btn-->
    <!--      :style="{ margin: '10px auto 0', display: 'block' }"-->
    <!--      outlined-->
    <!--      color="#2B46DF"-->
    <!--      @click="showMailPopup = true"-->
    <!--    >-->
    <!--      {{ $t('settings.emails.modifyEmailTemplate') }}-->
    <!--    </v-btn>-->
    <div class="h4-content">
      <h4>
        {{ $t('settings.emails.mailContent') }}
      </h4>
      <span />
    </div>
    <v-tabs class="mt-5">
      <template v-for="(mailtemplate, index) in custommails">
        <v-tab :key="'v-tab-content-mail-' + index">
          <h3
            class="legal-index-h3"
            :class="{
              'red--text': !myCompany.mails[mailtemplate.identifier][
                $i18n.locale
              ]
            }"
          >
            {{ mailtemplate.title }}
          </h3>
        </v-tab>
        <v-tab-item
          :style="{ 'margin-top': '20px' }"
          :key="'v-tab-item-content-mail-' + index"
        >
          <v-row>
            <v-col cols="12" sm="9">
              <MultilangEditor
                v-model="myCompany.mails[mailtemplate.identifier]"
                :ref="'mte_' + mailtemplate.identifier"
              />
            </v-col>
            <v-col cols="12" sm="3" class="pt-15">
              <v-card
                v-for="mailvar in mailtemplate.vars"
                :key="mailvar.id"
                color="primary white--text"
                class="mx-1 my-3"
                @click="addToMailEditor(mailvar, mailtemplate)"
              >
                <v-card-title class="px-3 py-2">
                  {{ mailvar.name }}
                </v-card-title>
                <v-card-text class="px-3 pb-2">
                  {{ mailvar.value }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </template>
    </v-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
// import MailEditor from '../helpers/mailEditor'
import mailtemplate from '@/helpers/mailtemplate'
import custommails from '@/helpers/custommails'
import MultilangEditor from '../helpers/multilangEditor'
// import MultilangEditor from '../helpers/multilangEditor'

export default {
  name: 'email',
  components: {
    MultilangEditor
    // MultilangEditor,
    // MailEditor
  },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showMailPopup: false,
      custommails: custommails().getTemplates()
    }
  },
  computed: {
    getFullMailTemplateHTML() {
      if (this.myCompany.identity.mail.html) {
        return this.myCompany.identity.mail.html
          .split('{{CONTENT}}')
          .join(
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium animi asperiores dolore ipsam minus molestiae numquam porro quas qui, repudiandae vero voluptatem voluptatibus! Delectus iusto maiores ullam ut vero.'
          )
      } else {
        return mailtemplate.getTemplate(this.myCompany.identity.displayName)
      }
    }
  },
  created() {
    this.initEmail()
  },
  methods: {
    initEmail() {
      if (!this.myCompany.identity.mail) {
        Vue.set(this.myCompany.identity, 'mail', {})
      }

      if (!this.myCompany.mails) {
        Vue.set(this.myCompany, 'mails', {})
      }

      this.custommails.forEach(mt => {
        if (!this.myCompany.mails[mt.identifier]) {
          Vue.set(this.myCompany.mails, mt.identifier, mt.body)
        }
      })
    },
    saveMailTemplate(val) {
      this.myCompany.identity.mail = {
        ...val.template,
        settings: val.settings
      }
    },
    addToMailEditor(mailvar, mailtemplate) {
      const el = this.$refs['mte_' + mailtemplate.identifier][0]

      el.insertText(`{{${mailvar.value}}}`)
    }
  },
  watch: {
    '$i18n.locale'() {
      this.custommails = custommails().getTemplates()
      this.custommails.forEach(mt => {
        if (!this.myCompany.mails[mt.identifier]) {
          Vue.set(this.myCompany.mails, mt.identifier, mt.body)
        }
      })
    }
  }
}
</script>

<style scoped>
.iframe-mail {
  width: 100%;
  height: 500px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 1);
  border: none;
  margin-top: 20px;
  border-radius: 3px;
}

.legal-index-h3 {
  text-transform: none;
  letter-spacing: normal;
}
</style>
